// --------------------------------------------------------------------------
// Status of app
// --------------------------------------------------------------------------

import React from 'react';
import styles from './status.module.css';

// --------------------------------------------------------------------------

class Status extends React.Component {

    // ----------------------------------------------------------------------

    render() {
        
        let statusMessage = this.props.status;
        let statusMessage2 = '';

        switch(this.props.status) {
            case 'success'  :
                statusMessage = 'Success';
                statusMessage2 = 'redirecting to product';
                break;
            case 'failure'  :
                statusMessage = 'Barcode not recognised';
                statusMessage2 = 'maybe I misread it... or this is not one of our wines';
                break;
            case 'skipping' :
                statusMessage = 'Scanning';
                statusMessage2 = 'ignoring mismatches';
                break;
            case 'validating':
                statusMessage = 'Validating';
                statusMessage2 = 'looking up barcode online';
                break;
            case 'scanning' :
                statusMessage = 'Scanning';
                statusMessage2 = 'searching for barcode';
                break;
            case 'redirecting':
                statusMessage = 'Redirecting';
                statusMessage2 = 'please wait...';
                break;
            case 'no internet':
                statusMessage = 'No internet';
                statusMessage2 = 'retrying in 5 seconds...';
                break;
        }

        return (
            <div className={styles.bsStatusMessage}>
                {statusMessage}
                <br />
                <small>{statusMessage2}</small>
            </div>
        )
    }

}

// --------------------------------------------------------------------------

export default Status;

// --------------------------------------------------------------------------
