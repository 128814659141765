// --------------------------------------------------------------------------
// This component is responsible for the UI context and what to do with
// results
// --------------------------------------------------------------------------
// 3rd party dependencies
// --------------------------------------------------------------------------

import React from 'react';

// --------------------------------------------------------------------------
// Other components
// --------------------------------------------------------------------------

import WineScanner from '../wine-scanner';

// --------------------------------------------------------------------------
// Internal dependencies
// --------------------------------------------------------------------------

import PastResults from './past-results.js';
import Status from './status.js';
import CameraAccessError from './camera-access-error';

// --------------------------------------------------------------------------

import closeButtonImage from './close-button.png';
import styles from './index.module.css';

// --------------------------------------------------------------------------

const maxPastResultLength = 10;

// --------------------------------------------------------------------------call asyn

class WineEventsScanner extends React.Component {

    // ----------------------------------------------------------------------
    // initialises scan
    // handles stop request
    // processes result
    // ----------------------------------------------------------------------

    constructor(props) {
        
        super(props);
        
        this.state = {
            timeLastStarted : (new Date()).getTime(),
            scanningTime    : 0,
            timerHandle     : null,
            scanning        : false,
            hasCameraAccess : true,
            status          : null,
            wines           : [],
            pastResults     : [],
            validCameraIds  : [],
            useCameraId     : null//localStorage['lastGoodCameraId']
        };

        let scannedWineData = localStorage['scannedWines'];
        if(scannedWineData!=null) {
            let scannedWines = JSON.parse(scannedWineData);
            this.state.pastResults = scannedWines;
        }
        
    }

    // ----------------------------------------------------------------------

    componentDidMount() {
        this.getValidCameraIds();
    }

    // ----------------------------------------------------------------------

    getNextCameraId() {

        if(this.state.validCameraIds.length<1) return null;

        let nextId = this.state.validCameraIds[0];
        
        for(let i=0; i<this.state.validCameraIds.length-1; i++) {
            if(this.state.validCameraIds[i]==this.state.useCameraId) {
                nextId = this.state.validCameraIds[i+1];
            }
        }

        return nextId;

    }

    // ----------------------------------------------------------------------

    switchToNextCamera() {

        let id = this.getNextCameraId();
        
        if(id==null) {
        
            this.setState( (state => { return {useCameraId:null, hasCameraAccess: false } }));
            console.log(`No valid camera to switch to`);
        
        } else {
        
            this.setState( (state => { return {useCameraId:id, hasCameraAccess: true } }));
            console.log(`Switching to ${id}`);
        
        }
    }

    // ----------------------------------------------------------------------

    async getValidCameraIds() {
        
        //let supportedConstraints = navigator.mediaDevices.getSupportedConstraints();
        console.log('Building up list of cameras');

        let rawDevices = await navigator.mediaDevices.enumerateDevices();
        let validCameraIds = [];
        
        for(let i=0; i<rawDevices.length; i++) {
            let device = rawDevices[i];
            if(device.kind=='videoinput') {
                validCameraIds.push(device.deviceId);
            }
        }

        this.setState( (state) => { return { validCameraIds : validCameraIds } });
        
        return true;

    }

    // ----------------------------------------------------------------------

    stopScanning() {
        
        //clearInterval(this.state.timerHandle);
        this.setState( (state) => { return { scanning:false, timerHandle:null } } );
        
    }

    // ----------------------------------------------------------------------

    startScanning(id) {
        
        if(id==null) { console.log('Using last good id'); id = localStorage['lastGoodCameraId']; }
        
        //if(id==null) id = this.getNextCameraId();
        console.log(id);

        // find redirect URL from original DIV tag
        let redirectURL = this.props.redirectURL;
        /*
        let self = this;
        let updateTimer = function() {
            let scanningTime = (new Date()).getTime() - self.state.timeLastStarted;
            self.setState( (state) => { return { scanningTime : Math.round(scanningTime/100)/10 } } );
        }
        let timerHandle = setInterval(updateTimer, 100 );
        */

        this.setState( (state) => { return {
            timeLastStarted : (new Date()).getTime(),
            //timerHandle     : timerHandle,
            scanning        : true,
            hasCameraAccess : true,
            useCameraId     : id,
            wines           : [],
            redirectURL     : redirectURL.value,
            status          : 'scanning'
        } } );

    }

    // ----------------------------------------------------------------------

    onScannerError(err) {

        console.log(err);
        // remove from list
        let curId = this.state.useCameraId;
        
        let newIds = this.state.validCameraIds.filter(x => x!=curId);
        
        if(newIds.length!=this.state.validCameraIds.length) {

            console.log('1st if');
            console.log(newIds);

            if(this.state.validCameraIds.length>1) {

                console.log('looking for id to automatically switch to');
                // first switch to next camera - while still have what will be previous to it!
                this.switchToNextCamera();
                // update list of valid cameras to exclude error camera

                this.setState( (state) => { return {
                    validCameraIds  : newIds
                } } );

            } else {

                this.setState( (state) => { return {
                    validCameraIds  : newIds,
                    hasCameraAccess : false
                } } );
            }

        } else if(curId==null && this.state.validCameraIds.length>0) {

            console.log('2nd if');
            // ok - we didn't specifically know which one we were using
            // and on desktop if it was an overconstrained error - happy to retry
            this.switchToNextCamera();

        } else {

            // error state
            console.log('else error');

            this.setState( (state) => { return {
                hasCameraAccess : false
            } } );

        }

    }

    // ----------------------------------------------------------------------

    updateStatus(status) {

        this.setState((state) => { return { status: status } });        

    }

    // ----------------------------------------------------------------------

    addWinesToPastResult(wines) {

        // build new list
        let newScannedWineList = [];
        let winesAdded = [];

        // build list of unique wines

        wines.forEach(element => {
            let sku = element.productId;
            if(!winesAdded.includes(sku)) {
                newScannedWineList.push(element);
                winesAdded.push(sku);
            }
        });
        
        this.state.pastResults.forEach(element => {
            let sku = element.productId;
            if(!winesAdded.includes(sku)) {
                newScannedWineList.push(element);
                winesAdded.push(sku);
            }
        });
        
        // Truncate
        if(newScannedWineList.length>maxPastResultLength) newScannedWineList.length = maxPastResultLength;

        // Remember
        localStorage['scannedWines'] = JSON.stringify(newScannedWineList);
        
        this.setState( (state) => { return {
            pastResults : newScannedWineList
        } });

    }

    // ----------------------------------------------------------------------

    foundWines(wines) {
        
        this.setState( (state) => { return {
            wines       : wines
            //scanning    : true
        } });

        if(this.state.useCameraId!=null) {
            localStorage['lastGoodCameraId'] = this.state.useCameraId;
        }

        this.addWinesToPastResult(wines); // we do this here because currently there is only one wine; it should really be done on selection of a wine; but atm no selection
        
        this.updateStatus('redirecting');

        let productId = wines[0].productId;
        let baseURL = this.props.baseURL;
        let redirectURL = this.props.redirectURL;
     
        redirectURL = `${baseURL}${redirectURL}`.replace('{sku}', productId);
        window.location.assign( redirectURL ); // lets just leave
        
    }

    // ----------------------------------------------------------------------

    render() {
        
        // css module was being overriden by surrounding page...
        const stopButtonPosition = {
            position        : 'fixed',
            top             : 'calc( 100% - 6vh - 1em - 16pt )',
            left            : '6vw',
            width           : 'calc( 100% - 12vw )',
            display         : 'block',            
            backgroundColor : 'rgba(0,0,0,0.5)',
            border          : 'none',//1px solid rgba(255,128,128,0.5)',
            zIndex          : 110,
            color           : '#fff',
            fontFamily      : 'Arial, Helvetica, sans-serif',
            fontSize        : '16pt',
            padding         : '0.5em'            
        };

        const nextButtonStyle = {
            position        : 'fixed',
            top             : 'calc( 100% - 6vh - 1em - 16pt - 32pt )',
            left            : '6vw',
            width           : 'calc( 100% - 12vw )',
            display         : 'block',
            backgroundColor : 'rgba(0,0,0,0.25)',
            border          : 'none',//1px solid rgba(255,128,128,0.5)',
            zIndex          : 110,
            color           : '#fff',
            fontFamily      : 'Arial, Helvetica, sans-serif',
            fontSize        : '10pt',
            padding         : '0.5em'
        };

        let showCameraOptions = this.state.validCameraIds.length>1;

        //let scanningTime = this.state.scanningTime;
        
        return (
            this.state.scanning ?

                <div className={styles.greyOuterBox}>

                    <div className={styles.whiteInnerBox}>

                        { this.state.hasCameraAccess ? (

                            <div>

                                <WineScanner
                                    onStop={ () => this.stopScanning() }
                                    onWinesFound= { (wines) => this.foundWines(wines) }
                                    onStatusUpdate={ (status) => this.updateStatus(status) }
                                    shadowRoot={ this.props.shadowRoot }
                                    onError={ (err) => this.onScannerError(err) }
                                    useCameraId={ this.state.useCameraId }
                                    />

                                <Status status={this.state.status} />

                                <button style={stopButtonPosition} className={styles.stopButton} onClick={ (e) => { this.stopScanning(); } } >Stop scanning</button>
                                
                            </div>
                        
                        ) : (

                            <CameraAccessError />

                        )}

                        { showCameraOptions ? <button style={nextButtonStyle} onClick={ (e) => {  this.switchToNextCamera(); return false; } } >Wrong camera lens? Try another one...</button> : null }

                        <a className={styles.closeButton} onClick={ (e) => { this.stopScanning(); return false; } } ><img alt="close button" width="32" height="32" className={styles.closeButtonImage} src={closeButtonImage} /></a>

                    </div>
                </div>
            :
                <div>
                    <button className="btn btn-primary" onClick={ (e) => { return this.startScanning(); } } >Start scanning</button>
                    
                    <PastResults results={this.state.pastResults} />
                </div>
        )
    }

}

// --------------------------------------------------------------------------
export default WineEventsScanner;
// --------------------------------------------------------------------------
