// ----------------------------------------------------------------------
// wrapper for the quagga2 library
// --------------------------------------------------------------------------
import React from 'react';
import PropTypes from 'prop-types';
import Quagga from '@ericblade/quagga2';
import './quagga.css';
// --------------------------------------------------------------------------
class Scanner extends React.Component {
    
    // ----------------------------------------------------------------------

    componentDidMount() {

        let self = this;

        let cameraId = this.props.useCameraId;

        console.log(`Using camera id: ${cameraId}`);

        // not sure really using this atm   
        let videoId = self.props.videoId;
        let videoTarget = null;
        if(self.props.shadowRoot != null) {
            if(videoId==null) videoId = '#interactive'; // shadowRoot must have a video ID - so go with default
            videoTarget = document.querySelector(self.props.shadowRoot).shadowRoot.querySelector(videoId);
        } else {
            videoTarget = videoId != null ? document.querySelector(videoId) : null;
        }

        let streamConstraints = cameraId == null ? { // not quite sure why these are swapped in portrait mode
                //width: { ideal: window.innerHeight },
                //height: { ideal: window.innerWidth },
                facingMode: { exact: 'environment' },
                //sharpness : 1,
                focusMode: { ideal: "continuous" }
            } : {
                //facingMode: 'environment',
                deviceId : cameraId,
                sharpness : 1
            };
        
        Quagga.init( {
            inputStream: {
                type            : 'LiveStream',//'LiveStream',
                target          : videoTarget,//self.props.shadowRoot != null ?  document.querySelector(self.props.shadowRoot).shadowRoot.querySelector(videoId) : null,
                constraints     : streamConstraints,
                singleChannel   : false
            },
            debug: true,
            locator: {
                patchSize: 'large', // x-small, small, medium, large, x-large
                halfSample: true,
            },
            numOfWorkers: 4,
            decoder: {
                readers: [ 'ean_reader' ],        
            },
            locate: true,
        }, (err) => {
            if (err) {
                this._onError(err);
                return console.log('Error starting Quagga:', err);
            }
            Quagga.start();
        });

        Quagga.onDetected(this._onDetected.bind(this));
        
        Quagga.onProcessed(function(result) {

            // this isn't really the place to resize the canvas - but nonethelesss
            let vw = window.innerWidth / 100;
            let vh = window.innerHeight / 100;
            
            let margin = 4;

            let drawingCtx = Quagga.canvas.ctx.overlay;
            let drawingCanvas = Quagga.canvas.dom.overlay;
            
            drawingCanvas.style.position = 'fixed';
            drawingCanvas.style.top = (margin*vh)+'px';
            drawingCanvas.style.left = (margin*vw)+'px';
            drawingCanvas.width = (100-2*margin)*vw;
            drawingCanvas.height = (100-2*margin)*vh;
                       
            let canvasWidth = parseInt(drawingCanvas.getAttribute("width"),0);
            let canvasHeight = parseInt(drawingCanvas.getAttribute("height"),0);
            
            /*
            if (result) {
              
                if (result.boxes) {

                    drawingCtx.clearRect(0, 0, canvasWidth, canvasHeight);
                    result.boxes.filter(function (box) {
                        return box !== result.box;
                    }).forEach(function (box) {
                        Quagga.ImageDebug.drawPath(box, {x: 0, y: 1}, drawingCtx, {color: "rgba(255,255,255,0.25)", lineWidth: 2});
                    });
                }

                if (result.box) {
                    Quagga.ImageDebug.drawPath(result.box, {x: 0, y: 1}, drawingCtx, {color: "rgba(255,255,255,0.25)", lineWidth: 2});
                }
                
                if (result.codeResult && result.codeResult.code) {
                    Quagga.ImageDebug.drawPath(result.line, {x: 'x', y: 'y'}, drawingCtx, {color: 'red', lineWidth: 3});
                }
                
            }
            */
            
            // THIS NEEDS DESIGN, SO MAY CHANGE, SO NOT GOING TO FOCUS ON DETAILS

            let frameX = canvasWidth*0.25;
            let frameY = canvasHeight*0.3;
            let frameS = 16;

            Quagga.ImageDebug.drawPath([
                [frameX         , frameY],
                [frameX + frameS, frameY],
                [frameX         , frameY + frameS],
            ], {x: 0, y: 1}, drawingCtx, {color: "#FFF", lineWidth: 2});

            Quagga.ImageDebug.drawPath([
                [canvasWidth-frameX         , frameY],
                [canvasWidth-(frameX + frameS), frameY],
                [canvasWidth-frameX         , frameY + frameS],
            ], {x: 0, y: 1}, drawingCtx, {color: "#FFF", lineWidth: 2});

            Quagga.ImageDebug.drawPath([
                [frameX         , canvasHeight-frameY],
                [frameX + frameS, canvasHeight-frameY],
                [frameX         , canvasHeight-(frameY + frameS)],
            ], {x: 0, y: 1}, drawingCtx, {color: "#FFF", lineWidth: 2});

            Quagga.ImageDebug.drawPath([
                [canvasWidth-frameX         , canvasHeight-frameY],
                [canvasWidth-(frameX + frameS), canvasHeight-frameY],
                [canvasWidth-frameX         , canvasHeight-(frameY + frameS)],
            ], {x: 0, y: 1}, drawingCtx, {color: "#FFF", lineWidth: 2});

        });
            
    }

    // ----------------------------------------------------------------------

    _onDetected(result) {
        this.props.onDetected(result);
    }

        // ----------------------------------------------------------------------

    _onError(err) {
        this.props.onError(err);
    }

    // ----------------------------------------------------------------------

    componentWillUnmount() {
        Quagga.offDetected(this._onDetected); // why?!
        Quagga.stop();
    }

    // ----------------------------------------------------------------------

    componentDidUpdate(prevProps, prevState) {

        if(prevProps.useCameraId!=this.props.useCameraId) {
            console.log('Changing device');
            try {
                Quagga.stop();
            } catch(e) {}
            this.componentDidMount();
        }

    }

    // ----------------------------------------------------------------------

    render() {

        return (
            <div id="scannerOverlay">
                <div id="interactive" className="viewport" />
            </div>
        )
    }
    // ----------------------------------------------------------------------

}

// --------------------------------------------------------------------------

Scanner.propTypes = {
    onDetected: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired
};

// --------------------------------------------------------------------------
export default Scanner;
// --------------------------------------------------------------------------