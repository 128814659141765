// --------------------------------------------------------------------------
// The purpose of this component is to expose a generic interface to other
// components that might use that is independent of a specific scanning
// library (as far as possible).
// Put another way, this component is tightly coupled to the libraries used
// so that components that use it do not have to be.
// --------------------------------------------------------------------------

import React from 'react';
import PropTypes from 'prop-types';

// --------------------------------------------------------------------------

import Scanner from './scanner-quagga2.js';

// --------------------------------------------------------------------------

class GenericScanner extends React.Component {

    // ----------------------------------------------------------------------
    // this provides the level above with the results in a standardised form
    // ----------------------------------------------------------------------

    render() {
                   
        return (
            
            <div> 
                <Scanner
                    shadowRoot={ this.props.shadowRoot } 
                    onDetected={ (result) => { this._onDetectedSingle(result.codeResult.code);  }  }
                    onError={ (err) => { this._onError(err); } }
                    useCameraId={ this.props.useCameraId }
                    />
            </div>

        );
    }

    // ----------------------------------------------------------------------

    _onDetectedSingle(code) {
        this.props.onDetected(
            [
                {
                    code: code,
                    confidence: 1.0 // future value
                }
            ]
        );
    }

    // ----------------------------------------------------------------------

    _onError(err) {
        this.props.onError(err);
    }

}
// --------------------------------------------------------------------------

GenericScanner.propTypes = {
    onDetected: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired
};

// --------------------------------------------------------------------------
export default GenericScanner;
// --------------------------------------------------------------------------
