// --------------------------------------------------------------------------
// ensure Mocha tests can use fetch
// --------------------------------------------------------------------------

require('isomorphic-fetch');

// --------------------------------------------------------------------------

export async function restGET(url) {
    return await fetch(url);
}

// --------------------------------------------------------------------------
