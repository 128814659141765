// --------------------------------------------------------------------------
// Status of app
// --------------------------------------------------------------------------

import React from 'react';
import styles from './camera-access-error.module.css';

// --------------------------------------------------------------------------

class CameraAccessError extends React.Component {

    // ----------------------------------------------------------------------

    render() {
       
        return (
            <div className={styles.bsCameraAccessError}>
                <h1>Unable to access Camera</h1>
                <p>There was a problem accessing the camera.</p>
                <p>Please make sure you:</p>
                <ul>
                    <li>Have your camera enabled</li>
                    <li>Have granted permission to this app when prompted</li>
                </ul>
                <p>If you have previously denied permission to access your camera, then you may need to check your browser settings to enable access.</p>
                <p>Commonly this can be accessed by clicking the lock icon beside the browser URL</p>
                <h2>Mac OS/iOS Chrome users</h2>
                <p>Mac users are advised to use Safari rather than Chrome. This is because Chrome may be denied access to the camera.</p>
            </div>
        )
    }

}

// --------------------------------------------------------------------------

export default CameraAccessError;

// --------------------------------------------------------------------------
