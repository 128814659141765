// --------------------------------------------------------------------------

import { mappings } from './ean-mappings';
import { restGET } from '../../helpers/rest.js';

// --------------------------------------------------------------------------

export async function getWineDataByBarCode(barcodeToTest) {

    // This API is going to change; so lets standardise our response now
    // Also, the current API call returns a single result
    // whereas the new API will return multiple results so so shall we 

    let url = `https://www.laithwaites.co.uk/api/product/item/${mappings[barcodeToTest]}`;

    let response = await restGET(url).catch( e => null ); // handle network error

    let data = response==null ? null : await response.json().catch( e => null ); // handle json not being readable

    let ok = data!=null && data.statusCode==0 ? true : false; // handle it working the way we want
    
    let productId = ok ? data.response.itemCode : null; // handle getting item code

    let internetError = response==null;

    return productId==null ? {
        error   : internetError,
        exists  : false,
        wines   : []

    } : {
        error   : false,
        exists  : true,
        wines   : [
            {
                productId       : productId,
                name            : data.response.name,
                thumbnailImage  : data.response.thumbnailImage
                //rawData         : data.response
            }
        ]
    };    
    
}

// --------------------------------------------------------------------------
