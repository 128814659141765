// --------------------------------------------------------------------------
// Top level loader
// --------------------------------------------------------------------------

import React from 'react';
import ReactDOM from 'react-dom';

// --------------------------------------------------------------------------

import WineEventsScanner from './components/wine-redirect-ui';

// --------------------------------------------------------------------------

const rootElement = document.getElementById("root");
let redirectURL = rootElement.attributes['redirecturl'].value;
let baseURL = rootElement.attributes['baseurl'].value;

if(baseURL=='' || baseURL==null) baseURL ='//';
if(redirectURL=='') redirectURL='product/{sku}';

function App() {
    return (
        <WineEventsScanner
            baseURL={baseURL}    
            redirectURL={redirectURL}

            />
    );
}

ReactDOM.render(<App />, rootElement);
