// --------------------------------------------------------------------------
// Purpose of this component is to convert EAN to wine information,
// ensuring looks up no more than a single EAN at a time, looks up the same
// EAN no more than once, and queues any additional scan data found in the
// meantime
// --------------------------------------------------------------------------

import React from 'react';
import PropTypes from 'prop-types';

// --------------------------------------------------------------------------

import GenericScanner from '../generic-scanner';
import { getWineDataByBarCode } from './functions';

// --------------------------------------------------------------------------

const stateIdle = 'idle';
const stateProcessing = 'processing';
const stateWaitingOnAPI = 'waiting';
const stateDone = 'done';
const statePaused = 'paused';

// --------------------------------------------------------------------------

class WineScanner extends React.Component {

    // ----------------------------------------------------------------------
    // validates wines
    // - queues up wines for API processing
    // ----------------------------------------------------------------------

    state = {
        
        // new codes

        analysisStateNumber : 0,
        analysisState       : stateIdle,
        barcodesToTest      : '',
        barcodeTesting      : '',
        barcodesToIgnore    : '',
        barcodeFound        : '',

    };

    // ----------------------------------------------------------------------

    queueBarCode(codes) {

        let code = codes[0].code; 

        this.setState( (state) => {
            
            let newQueue = state.barcodesToTest;
            let newBarcodeTesting = state.barcodeTesting;

            newQueue = newQueue==='' ? code : newQueue + ',' + code;
            
            return {
                barcodesToTest : newQueue,
                barcodeTesting : newBarcodeTesting
            };

        } );
    }

    // ----------------------------------------------------------------------

    async triggerLookup() {
       
        let self = this;

        let barcodeToTest = this.state.barcodeTesting;
        
        if(this.state.barcodesToIgnore.indexOf(barcodeToTest)>=0) {
            
            this.props.onStatusUpdate('skipping');

            // don't bother
            this.setState((state)=>{ return {
                analysisStateNumber : state.analysisStateNumber + 1,
                analysisState       : stateIdle,
                barcodeTesting      : ''
            }; })

        } else {
            
            // Look it up
            this.setState((state)=>{ return { analysisStateNumber: state.analysisStateNumber + 1, analysisState: stateWaitingOnAPI }; })
            
            this.props.onStatusUpdate('validating');

            let result = await getWineDataByBarCode(barcodeToTest);

            if(result.error) {

                this.props.onStatusUpdate('no internet');
                this.queueBarCode(barcodeToTest);
                self.setState((state) => { return {
                    analysisStateNumber     : state.analysisStateNumber + 1,
                    analysisState           : statePaused,
                } } );

            } else {

                if(!result.exists) {

                    self.setState((state) => { return {
                        analysisStateNumber     : state.analysisStateNumber + 1,
                        analysisState           : stateIdle,
                        barcodeTesting          : '',
                        barcodesToIgnore        : state.barcodesToIgnore + ',' + barcodeToTest
                    }});

                    this.props.onStatusUpdate('failure');

                } else {
                    
                    this.props.onStatusUpdate('success');

                    // not strictly necessary since redirecting
                    self.setState((state) => { return {
                        analysisStateNumber     : state.analysisStateNumber + 1,
                        analysisState           : stateDone,
                        barcodeTesting          : '',
                        barcodeFound            : result.wines[0].productId // ONLY makes sense if we are looking for a single result
                    }});
                    
                    self.props.onWinesFound(
                        result.wines
                    );

                }
            }

        }
    }

    // ----------------------------------------------------------------------

    componentDidUpdate(prevProps, prevState) { // ok - this is going to do a LOT of the work co-ordinating everything

        // State changes triggered on component update
        // -------------------------------------------
        // stateIdle            ---> stateProcessing (if something in the queue - note: barcode scanner asynchronously puts things in the queue, regardless of current state)
        //
        // stateProcessing      -+-> stateWaitingOnAPI (if have to call API to look up barcode)
        //                       +-> stateIdle (if already know the answer is not a match)
        //
        // State changes triggered by external response
        // --------------------------------------------
        // stateWaitingOnAPI    -+-> stateDone (if API returns a match - triggered on callback)
        //                       +-> stateIdle (if API does not match - triggered on callback)

        let action = prevState.analysisStateNumber!==this.state.analysisStateNumber ? 'changedto' : 'unchanged';
        let self = this;

        switch(action + ':' + this.state.analysisState) {

            case 'changedto:'+statePaused:
                // do nothing
                setTimeout( function() {
                        // we can fairly safely assume that the state will remain paused...
                        self.setState((state) => { return {
                            analysisStateNumber     : state.analysisStateNumber + 1,
                            analysisState           : stateIdle,
                        } } );
                    }, 5000);
                break;

            case 'changedto:'+stateProcessing:

                // how can I be sure that it doesn't do this more than once...
                this.triggerLookup();
                
                break;

            case 'changedto:'+stateIdle:
            case 'unchanged:'+stateIdle:

                // (still) not doing anything at the moment, so lets check the queue and maybe do something
                if(this.state.barcodesToTest!=='') {

                    let list = this.state.barcodesToTest.split(',');
                    let newList = list.slice(1).join(',');
                    
                    this.setState( (state) => {
                        return {
                            analysisStateNumber: state.analysisStateNumber + 1,
                            analysisState: stateProcessing,
                            barcodeTesting: list[0],
                            barcodesToTest: newList
                            }
                        }
                    );
                    
                }

                break;

        }

    }

    // ----------------------------------------------------------------------

    render() {

        return (
                 
            <div>
            
                <GenericScanner
                    shadowRoot={ this.props.shadowRoot }
                    onDetected={ (result) => this.queueBarCode(result)  }
                    onError={ (err) => this.props.onError(err) } 
                    useCameraId={ this.props.useCameraId }
                    />
                
            </div>
               
        );
    }

}

// --------------------------------------------------------------------------

WineScanner.propTypes = {
    onStop: PropTypes.func.isRequired,
    onWinesFound: PropTypes.func.isRequired,
    onStatusUpdate: PropTypes.func.isRequired
};

// --------------------------------------------------------------------------

export default WineScanner;

// --------------------------------------------------------------------------
