// --------------------------------------------------------------------------
// List of wines
// --------------------------------------------------------------------------

import React from 'react';

// --------------------------------------------------------------------------

import styles from './past-results.module.css';

// --------------------------------------------------------------------------

class PastResults extends React.Component {

    // ----------------------------------------------------------------------

    render() {
        
        let results = this.props.results;

        const baseUrl = 'https://www.laithwaites.co.uk';
        const url = `${baseUrl}/product/`;
        let key = 1;
        
        const listItems = results.map( (wineData) =>
            <div className={styles.item} key={key++}>                
                <a href={`${url}${wineData.productId}`}>
                    <img alt={`${wineData.name} - ${wineData.vintage}`} src={`${baseUrl}${wineData.thumbnailImage}`} />                    
                </a>
            </div>
            );
        return results.length > 0 ? (
            <div>
                <h3 className={styles.heading}>Previous Scan Results</h3>
                <div className={styles.container}>
                    {listItems}
                </div>
            </div>
        ) : null

    }

}

// --------------------------------------------------------------------------

export default PastResults;

// --------------------------------------------------------------------------
